import { createSettingsParams, SettingsParamType } from '@wix/tpa-settings';

// TODO: all default simple type values shoul come from enums

export type ISettingsParams = {
  forceExpandedStateInEditor: SettingsParamType.Number;
  layout: SettingsParamType.Text;
  textHeader: SettingsParamType.Text;
  textInputPlaceholder: SettingsParamType.Text;
  textButtonOnline: SettingsParamType.Text;
  textButtonOffline: SettingsParamType.Text;
  backgroundColorUseCustom: SettingsParamType.Boolean;
  applyAssistantMessageShadow: SettingsParamType.Boolean;
  minimizedButtonPosition: SettingsParamType.Text;
  minimizedButtonDisplay: SettingsParamType.Text;
  minimizedButtonIcon: SettingsParamType.Text;
  minimizedButtonNudgeAnimation: SettingsParamType.Boolean;
  minimizedButtonShadow: SettingsParamType.Boolean;

  displayChatbotAvatar: SettingsParamType.Boolean;
  applyShadow: SettingsParamType.Boolean;
};

export default createSettingsParams<ISettingsParams>({
  forceExpandedStateInEditor: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => 0,
  },
  layout: {
    type: SettingsParamType.Text,
    getDefaultValue: () => 'left',
  },
  textHeader: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('app.settings.text.defaultHeader'),
  },
  textInputPlaceholder: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('app.settings.text.defaultInputPlaceholder'),
  },
  textButtonOnline: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) =>
      t('app.settings.text.defaultMinimizedTabOnline'),
  },
  textButtonOffline: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) =>
      t('app.settings.text.defaultMinimizedTabOffline'),
  },
  backgroundColorUseCustom: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => false,
  },
  displayChatbotAvatar: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  applyShadow: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  applyAssistantMessageShadow: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => false,
  },
  minimizedButtonPosition: {
    type: SettingsParamType.Text,
    getDefaultValue: () => 'floating',
  },
  minimizedButtonDisplay: {
    type: SettingsParamType.Text,
    getDefaultValue: () => 'textIcon',
  },
  minimizedButtonIcon: {
    type: SettingsParamType.Text,
    getDefaultValue: () => 'magic-1',
  },
  minimizedButtonNudgeAnimation: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => false,
  },
  minimizedButtonShadow: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => false,
  },
});
