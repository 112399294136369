import React from 'react';

import PlaceholderImage from '@/assets/illustrations/placeholderImage.svg';
import PlaceholderImageSmall from '@/assets/illustrations/placeholderImageSmall.svg';

import { st, classes } from './ImageBlock.st.css';

export interface ImageBlockProps {
  aspectRatio?: number; // Width/Height, for example 4/3
  isSmall?: boolean;
}

// A block that maintains desired aspect ratio and has a placeholder image
export const ImageBlock: React.FC<
  ImageBlockProps & React.HTMLAttributes<HTMLElement>
> = ({ aspectRatio, isSmall, children, ...rootElementAttrs }) => (
  <div
    className={st(classes.root)}
    style={{ aspectRatio }}
    {...rootElementAttrs}
  >
    <div className={st(classes.placeholderImage)}>
      {isSmall ? <PlaceholderImageSmall /> : <PlaceholderImage />}
    </div>
    <div className={st(classes.content)}>{children}</div>
  </div>
);
