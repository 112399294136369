import { DependencyList, useCallback, useRef } from 'react';

export const DEFAULT_DEBOUNCE_DELAY_MS = 100;

export function useDebouncedCallback<T extends (...args: any) => any>(
  todo: T,
  deps: DependencyList,
  delay = DEFAULT_DEBOUNCE_DELAY_MS,
): (...args: Parameters<T>) => void {
  const timerRef = useRef<ReturnType<typeof setTimeout>>();
  return useCallback(
    (...args: Parameters<T>) => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      timerRef.current = setTimeout(() => {
        delete timerRef.current;
        todo(...args);
      }, delay);
    },
    [todo, deps, delay],
  );
}
