import {
  StyleParamType,
  wixColorParam,
  wixFontParam,
  createStylesParams,
} from '@wix/tpa-settings';

export type IStylesParams = {
  widgetWidth: StyleParamType.Number;
  widgetHeight: StyleParamType.Number;

  headerBackground: StyleParamType.Color;
  headerFont: StyleParamType.Font;
  headerTextColor: StyleParamType.Color;
  minimizeIconColor: StyleParamType.Color;
  avatarColor: StyleParamType.Color;

  mainBackground: StyleParamType.Color;
  shadowColor: StyleParamType.Color;
  shadowBlur: StyleParamType.Number;
  shadowSpread: StyleParamType.Number;
  shadowDirection: StyleParamType.Number;

  messagesVisitorMessageColor: StyleParamType.Color;
  messagesVisitorMessageFont: StyleParamType.Font;
  messagesAssistantMessageColor: StyleParamType.Color;
  messagesAssistantMessageFont: StyleParamType.Font;
  messagesAssistantMessageBackground: StyleParamType.Color;
  messagesAssistantMessageBorderColor: StyleParamType.Color;
  messagesAssistantMessageBorderWidth: StyleParamType.Number;
  messagesAssistantMessageBorderRadius: StyleParamType.Number;
  messagesAssistantMessageLinkColor: StyleParamType.Color;

  messagesAssistantMessageShadowColor: StyleParamType.Color;
  messagesAssistantMessageShadowBlur: StyleParamType.Number;
  messagesAssistantMessageShadowSpread: StyleParamType.Number;
  messagesAssistantMessageShadowDirection: StyleParamType.Number;

  messagesButtonBackground: StyleParamType.Color;
  messagesButtonBorderRadius: StyleParamType.Number;
  messagesButtonBorderColor: StyleParamType.Color;
  messagesButtonFont: StyleParamType.Font;
  messagesButtonColor: StyleParamType.Color;

  messagesButtonSecondaryBackground: StyleParamType.Color;
  messagesButtonSecondaryBorderRadius: StyleParamType.Number;
  messagesButtonSecondaryBorderColor: StyleParamType.Color;
  messagesButtonSecondaryFont: StyleParamType.Font;
  messagesButtonSecondaryColor: StyleParamType.Color;

  messagesCardBackground: StyleParamType.Color;
  messagesCardBorderColor: StyleParamType.Color;
  messagesCardBorderWidth: StyleParamType.Number;
  messagesCardBorderRadius: StyleParamType.Number;
  messagesCardTitleFont: StyleParamType.Font;
  messagesCardTitleColor: StyleParamType.Color;
  messagesCardDescriptionFont: StyleParamType.Font;
  messagesCardDescriptionColor: StyleParamType.Color;

  inputDefaultBackground: StyleParamType.Color;
  inputHoverBackground: StyleParamType.Color;
  inputFocusBackground: StyleParamType.Color;

  inputDefaultBorderColor: StyleParamType.Color;
  inputHoverBorderColor: StyleParamType.Color;
  inputFocusBorderColor: StyleParamType.Color;

  inputDefaultBorderSize: StyleParamType.Number;
  inputHoverBorderSize: StyleParamType.Number;
  inputFocusBorderSize: StyleParamType.Number;

  inputDefaultBorderRadius: StyleParamType.Number;
  inputHoverBorderRadius: StyleParamType.Number;
  inputFocusBorderRadius: StyleParamType.Number;

  inputTextFont: StyleParamType.Font;
  inputTextColor: StyleParamType.Color;
  inputPlaceholderColor: StyleParamType.Color;
  inputIconColor: StyleParamType.Color;

  minimizedButtonBackground: StyleParamType.Color;
  minimizedButtonRadius: StyleParamType.Number;
  minimizedButtonFont: StyleParamType.Font;
  minimizedButtonFontColor: StyleParamType.Color;
  minimizedButtonIconColor: StyleParamType.Color;

  minimizedButtonShadowColor: StyleParamType.Color;
  minimizedButtonShadowBlur: StyleParamType.Number;
  minimizedButtonShadowSpread: StyleParamType.Number;
  minimizedButtonShadowDirection: StyleParamType.Number;

  // TODO: what it for POC only?
  backgroundColor: StyleParamType.Color;
  textFont: StyleParamType.Font;
  textColor: StyleParamType.Color;
};

export enum WidgetWidthConstrains {
  Min = 400,
  Max = 600,
}

export enum WidgetHeightConstrains {
  Min = 60,
  Max = 100,
}

export default createStylesParams<IStylesParams>({
  widgetWidth: {
    type: StyleParamType.Number,
    getDefaultValue: ({ isRTL, isEditorX, dimensions }) => {
      return 480;
    },
  },
  widgetHeight: {
    type: StyleParamType.Number,
    getDefaultValue: ({ isRTL, isEditorX, dimensions }) => {
      return 80;
    },
  },
  backgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-3'),
  },
  textFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 20,
    }),
  },
  textColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  headerBackground: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  headerFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Page-title', {
      size: 16,
    }),
  },
  headerTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  minimizeIconColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  avatarColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  mainBackground: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-2'),
  },
  shadowColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('black', 0.8),
  },
  shadowBlur: {
    type: StyleParamType.Number,
    getDefaultValue: () => 30,
  },
  shadowSpread: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  shadowDirection: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  messagesVisitorMessageColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  messagesVisitorMessageFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: 16 }),
  },
  messagesAssistantMessageColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  messagesAssistantMessageFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: 16 }),
  },
  messagesAssistantMessageBackground: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  messagesAssistantMessageBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0),
  },
  messagesAssistantMessageBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  messagesAssistantMessageBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 16,
  },
  messagesAssistantMessageLinkColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  messagesAssistantMessageShadowColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.12),
  },
  messagesAssistantMessageShadowBlur: {
    type: StyleParamType.Number,
    getDefaultValue: () => 8,
  },
  messagesAssistantMessageShadowSpread: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  messagesAssistantMessageShadowDirection: {
    type: StyleParamType.Number,
    getDefaultValue: () => 315,
  },
  messagesButtonBackground: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  messagesButtonBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  messagesButtonBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 4,
  },
  messagesButtonFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: 14 }),
  },
  messagesButtonColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  messagesButtonSecondaryBackground: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  messagesButtonSecondaryBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  messagesButtonSecondaryBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 16,
  },
  messagesButtonSecondaryFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: 14 }),
  },
  messagesButtonSecondaryColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  messagesCardBackground: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  messagesCardBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.2),
  },
  messagesCardBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  messagesCardBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 8,
  },
  messagesCardTitleFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
      style: { bold: true, italic: false, underline: false },
    }),
  },
  messagesCardTitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  messagesCardDescriptionFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: 16 }),
  },
  messagesCardDescriptionColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  inputDefaultBackground: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  inputHoverBackground: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  inputFocusBackground: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  inputDefaultBorderSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  inputHoverBorderSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  inputFocusBorderSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  inputDefaultBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 40,
  },
  inputHoverBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 40,
  },
  inputFocusBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 40,
  },
  inputTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: 16 }),
  },
  inputTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  inputPlaceholderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-3'),
  },
  inputIconColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  inputDefaultBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.6),
  },
  inputHoverBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  inputFocusBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  minimizedButtonBackground: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  minimizedButtonRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 40,
  },
  minimizedButtonFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: 16 }),
  },
  minimizedButtonFontColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-2'),
  },
  minimizedButtonIconColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  minimizedButtonShadowColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.4),
  },

  minimizedButtonShadowBlur: {
    type: StyleParamType.Number,
    getDefaultValue: () => 8,
  },
  minimizedButtonShadowSpread: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  minimizedButtonShadowDirection: {
    type: StyleParamType.Number,
    getDefaultValue: () => 2,
  },
});
