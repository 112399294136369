import React, {
  ChangeEvent,
  useCallback,
  KeyboardEventHandler,
  useMemo,
  useEffect,
} from 'react';

import { IconButton } from 'wix-ui-tpa';

import { useSettings } from '@wix/tpa-settings/react';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';

import { useAppSelector } from '@/store';
import { selectFlags, selectSettings } from '@/features';

import settingsParams from 'AiAssistantWidget/settingsParams';

import { SendIcon } from '../svg';

import { st, classes, cssStates } from './Input.st.css';

type InputProps = {
  value: string;
  onChange: (value: string) => void;
  onSubmit: () => void;
  disabled?: boolean;
};

export const Input = ({ value, onChange, onSubmit, disabled }: InputProps) => {
  const { t } = useTranslation();
  const { isEditor, isMobile } = useEnvironment();
  const settings = useSettings();
  const isOnline = useAppSelector(selectSettings.isOnline);
  const offlineDisabledMessaging = useAppSelector(
    selectSettings.offlineDisabledMessaging,
  );
  const shouldDisableInput = useAppSelector(selectSettings.shouldDisableInput);

  const isContact = useAppSelector(selectFlags.isContact);

  const [Textarea, setTextarea] =
    React.useState<React.ComponentType<any> | null>(null);

  const submitRef = React.useRef<any>(null);
  const textareaRef = React.useRef<HTMLTextAreaElement>(null);

  const inputPlaceholder = useMemo(() => {
    if (isOnline || isEditor) {
      return settings.get(settingsParams.textInputPlaceholder);
    }

    if (!isOnline && (isContact || !offlineDisabledMessaging)) {
      return t('app.settings.text.disabledInputTooltipForContact');
    }

    return t('app.settings.text.disabledInputTooltip');
  }, [isContact, isEditor, isOnline, settings, offlineDisabledMessaging, t]);

  const disabledInput = useMemo(
    () => disabled || (shouldDisableInput && !isEditor),
    [isEditor, disabled, shouldDisableInput],
  );

  const onInputChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      onChange(e.target.value);
    },
    [onChange],
  );

  const onKeyDown = useCallback<KeyboardEventHandler<HTMLTextAreaElement>>(
    (e) => {
      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();
        onSubmit();
      }
    },
    [onSubmit],
  );

  useEffect(() => {
    if (!Textarea) {
      // TODO: add skeleton loader
      import('react-textarea-autosize').then((module) => {
        setTextarea(() => module.default);
      });
    }
  }, [Textarea]);

  useEffect(() => {
    const el = submitRef.current?.wrappedComponentRef?.innerComponentRef;
    if (Textarea && el) {
      el.disabled = disabledInput;
      if (!disabledInput) {
        // debugger;
        textareaRef.current?.focus();
      }
    }
  }, [Textarea, disabledInput]);

  if (!Textarea) {
    return null;
  }

  return (
    <div
      className={st(
        classes.root,
        cssStates({ disabled: disabledInput, isMobile }),
      )}
    >
      <div className={st(classes.inputContainer)}>
        <Textarea
          value={value}
          onChange={onInputChange}
          onKeyDown={onKeyDown}
          maxRows={6}
          className={st(classes.input)}
          placeholder={inputPlaceholder}
          disabled={disabledInput}
          autoFocus={!isEditor}
          ref={textareaRef}
          InputProps={{}}
        />
        <div className={st(classes.submit)} onClick={onSubmit}>
          <IconButton
            aria-disabled={disabledInput}
            disabled
            innerRef={submitRef}
            icon={
              <SendIcon
                className={st(
                  classes.submitIcon,
                  cssStates({ emptyInput: !value.length }),
                )}
              />
            }
          />
        </div>
      </div>
    </div>
  );
};
