import { createSelector } from '@reduxjs/toolkit';

import { OfflineStrategy } from '@wix/ambassador-innovation-widget-v1-message/types';

import { RootState } from '@/store';

import { selectFlags } from '../flags';

export const value = (state: RootState) => state.settings.value;
export const request = (state: RootState) => state.settings.request;

export const isLoaded = createSelector(value, (v) => Boolean(v));
export const isLoading = createSelector(request, (r) => r === 'loading');
export const isError = createSelector(request, (r) => r === 'error');
export const neverLoaded = createSelector(
  value,
  request,
  (v, r) => v === null && r === 'idle',
);

export const isOnline = createSelector(value, (v) =>
  Boolean(v?.assistantOnline),
);

export const widgetSettings = createSelector(value, (v) => v?.widgetSettings);

export const shouldShowWidget = createSelector(
  value,
  isOnline,
  (v, o) =>
    o ||
    (Boolean(v?.widgetSettings?.strategy) &&
      v?.widgetSettings?.strategy !== OfflineStrategy.HIDDEN),
);

export const offlineStrategy = createSelector(
  widgetSettings,
  (settings) => settings?.strategy,
);

export const offlineStrategyIsChat = createSelector(
  offlineStrategy,
  (s) => s === OfflineStrategy.CHAT,
);

export const offlineDisabledMessaging = createSelector(
  isOnline,
  offlineStrategy,
  (online, strategy) => !online && strategy === OfflineStrategy.CONTACT,
);

export const offlineShouldShowContactForm = createSelector(
  isOnline,
  offlineStrategy,
  (online, strategy) =>
    !online &&
    (strategy === OfflineStrategy.CONTACT ||
      strategy === OfflineStrategy.CONTACT_LIGHT),
);

export const shouldDisableInput = createSelector(
  isOnline,
  offlineStrategy,
  selectFlags.isContact,
  (online, strategy, isContact) =>
    !online && strategy === OfflineStrategy.CONTACT && !isContact,
);

export const introMessageSettings = createSelector(
  value,
  (v) => v?.widgetSettings?.introMessage?.message,
);
export const introMessage = createSelector(
  introMessageSettings,
  isOnline,
  (m, online) => online && m?.enabled && m?.text,
);
export const legalMessageSettings = createSelector(
  value,
  (v) => v?.widgetSettings?.introMessage?.legalDisclaimer,
);
export const legalMessage = createSelector(
  legalMessageSettings,
  isOnline,
  (m, online) => online && m?.enabled && m?.text,
);
export const offlineMessageSettings = createSelector(
  value,
  (v) => v?.widgetSettings?.introMessage?.offlineDisclaimer,
);
export const offlineMessage = createSelector(
  offlineMessageSettings,
  isOnline,
  (m, online) => !online && m?.enabled && m?.text,
);

export const contactFormFields = createSelector(
  value,
  (v) => v?.widgetSettings?.contactForm?.fields,
);
